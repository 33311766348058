.calendar {
  // width: 500px;
  border: 1px solid #ccc;
  border-radius: 4px;

  &-header{
    display: flex;
    padding: 4px;
  }
  &-header-left{
    display: flex;
    padding: 4px;
    gap: 4px;
  }

  &-body-header{
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px 4px;
  }

  &-body{
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(6, 1fr);
    gap: 4px;
    padding: 4px;
    
  }

  &-body-item{
    place-self: stretch;
    padding: 6px;
    border-top: 1px solid #ccc;
    height: 50px;
    cursor: pointer;
    > div{
      height: 100%;
    }
  }

  &-body-item.other-month{
    color: #ccc;
  }
  .number{
    width: 28px;
    height: 28px;
    line-height: 28px;
    text-align: center;
  }
  &-body-item-selected{
    > .number{
      color: #fff;
      background-color: #007bff;
      border-radius: 50%;
    }
  }
}
